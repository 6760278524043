<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li>Dokumente</li>
            </ul>
            <div class="header-actions">
              <p><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents/new'" class="button button-orange button-small">Dokument hinzufügen ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <div class="admin-body">
              <div v-if="documents.length == 0">
                <p>Noch keine Dokumente.</p>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="40%">Name</th>
                      <th width="40%">Datei</th>
                      <th width="20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="document in documents" v-bind:key="document.id">
                      <td>{{ document.name }}</td>
                      <td><a v-if="document.document" :href="document.document" style="font-weight:600" target="blank">Jetzt downloaden ›</a></td>
                      <td style="text-align:right">
                        <a @click="delete_document(document)" style="margin-right:10px;cursor:pointer;display:inline-block;vertical-align:middle;"><span class="material-icons" style="margin-right:0">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_documents',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle: {},
      documents: []
    }
  },
  methods: {
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicle = response.data.vehicle;
      })
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/documents', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.documents = response.data.vehicle_documents;
      })
    },
    delete_document(document) {
      if(confirm("Wirklich löschen?")) {
        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.vehicle.id+'/documents/'+document.id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(() => {
          this.$notify({
            group: 'alert',
            type: 'success',
            title: 'Erfolgreich gespeichert.'
          });
          this.get_data();
        })
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
